<template>
  <NuxtLink
    :to="parent.url"
    class="breadcrumbs text-small-regular pt-16 py-32 block w-fit-content group"
  >
    <div class="mr-8 inline-block align-middle">
      <ArrowLongLeftIcon
        class="w-20 h-20"
      />
    </div>
    <div class="inline-block align-middle underline group-hover:no-underline">{{ parent.name }}</div>
  </NuxtLink>
</template>

<script setup lang="ts">
import ArrowLongLeftIcon from '@heroicons/vue/24/outline/esm/ArrowLongLeftIcon';
import { useGlobalContentStore } from '~/store/globalContent';
const globalContentStore = useGlobalContentStore();
const host = globalContentStore.host;

type BreadCrumbs = {
  name: string;
  url: string;
}

const props = defineProps<{
  parent: BreadCrumbs;
}>();

const jsonLd = {
  '@context': 'http://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: [{
    '@type': 'ListItem',
    position: 1,
    item: {
      '@id': host + props.parent.url,
      'name': props.parent ? props.parent.name : '',
    },
  }],
};

useJsonld(jsonLd);
</script>
<style scoped>
.breadcrumbs {
  &--white {
    @apply absolute top-16 text-white z-30 left-24 md:left-56;

    @screen xl {
      left: calc(50% - (1372px / 2) + 3.5rem);
    }
  }

}
</style>
